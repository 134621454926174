import React, { useContext } from "react";
import { FaCaretRight } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import BaseLayout from "../global/BaseLayout";
import TransparentHeader from "../global/TransparentHeader";
import { ButtonContent } from '../global/boton/ButtonContent'
import { BiPhoneCall, BiCalendar } from 'react-icons/bi'
import { MdOutlineEmail } from 'react-icons/md'
import { FaRegClock } from 'react-icons/fa'
import SocialMedia from "../global/SocialMedia";
import CounterUp from '../global/CounterUp'
import Values from "../About/Values";
import Map from "../Contact/MapContent";

function ServicesDetail() {
    const { rpdata } = useContext(GlobalDataContext);
    const { id } = useParams();
    // console.log(id);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const bgheaderImage = [
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F6394b6e36fcaff8167e636e3%2Fstock%2FNV%20Highland%20Landscaping%20LLC-2022-12-12T15%3A26%3A40.521Z-0.png?alt=media&token=4f409388-70e8-4647-a49b-f715eed13a72',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F6394b6e36fcaff8167e636e3%2Fstock%2FNV%20Highland%20Landscaping%20LLC-2022-12-12T15%3A26%3A40.522Z-1.jpg?alt=media&token=aa2e8682-9ca2-49d3-aeac-3a83a0286ff6',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F6394b6e36fcaff8167e636e3%2Fstock%2FNV%20Highland%20Landscaping%20LLC-2022-12-12T15%3A26%3A40.523Z-13.jpg?alt=media&token=d4a2e4c6-9adf-4728-a70c-e9253421ee4f',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F6394b6e36fcaff8167e636e3%2Fstock%2FNV%20Highland%20Landscaping%20LLC-2022-12-12T15%3A26%3A40.522Z-2.jpg?alt=media&token=d8c77699-6754-40a0-a7ac-1ee641cc9950',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F6394b6e36fcaff8167e636e3%2Fstock%2FNV%20Highland%20Landscaping%20LLC-2022-12-12T15%3A26%3A40.522Z-4.jpeg?alt=media&token=4ccea4bd-3518-4fb7-b667-c3859518cf0d',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F6394b6e36fcaff8167e636e3%2Fstock%2FNV%20Highland%20Landscaping%20LLC-2022-12-12T15%3A26%3A40.522Z-7.jpg?alt=media&token=576c1e15-64a9-4d92-9bab-5b1ef3dfde5b',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F6394b6e36fcaff8167e636e3%2Fstock%2FNV%20Highland%20Landscaping%20LLC-2022-12-12T15%3A26%3A40.522Z-5.jpg?alt=media&token=50015a99-6e82-4d86-8871-1286c9f7c3df',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F6394b6e36fcaff8167e636e3%2Fstock%2FNV%20Highland%20Landscaping%20LLC-2022-12-10T17%3A39%3A51.848Z-6.jpg?alt=media&token=0262e757-cd9e-4480-a2a3-4eeb0cb4f6b4',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F6394b6e36fcaff8167e636e3%2Fgallery%2FNV%20Highland%20Landscaping%20LLC-2022-12-10T16%3A41%3A53.993Z-58.jpg?alt=media&token=36702a52-93cd-4707-9260-536aa9e7c746',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F6394b6e36fcaff8167e636e3%2Fstock%2FNV%20Highland%20Landscaping%20LLC-2022-12-10T17%3A39%3A51.848Z-7.jpeg?alt=media&token=c873c368-effe-4b03-b58c-265bfac69210',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F6394b6e36fcaff8167e636e3%2Fstock%2FNV%20Highland%20Landscaping%20LLC-2022-12-12T15%3A26%3A40.522Z-8.jpg?alt=media&token=ecc9fd4b-0d8f-4288-87f0-884dbe18c15a',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F6394b6e36fcaff8167e636e3%2Fstock%2FNV%20Highland%20Landscaping%20LLC-2022-12-12T15%3A26%3A40.522Z-9.jpg?alt=media&token=b7f3d7cb-924f-48db-b982-82adf1b13188',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F6394b6e36fcaff8167e636e3%2Fstock%2FNV%20Highland%20Landscaping%20LLC-2022-12-12T15%3A26%3A40.522Z-10.jpg?alt=media&token=13712e5e-af27-4a67-9cd2-c91e7e60745f',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F6394b6e36fcaff8167e636e3%2Fstock%2FNV%20Highland%20Landscaping%20LLC-2022-12-12T15%3A26%3A40.522Z-11.jpg?alt=media&token=2b07bc48-5b5d-42ec-b69a-c79069f9685d',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F6394b6e36fcaff8167e636e3%2Fstock%2FNV%20Highland%20Landscaping%20LLC-2022-12-10T17%3A39%3A51.848Z-3.jpg?alt=media&token=b5bbd568-1612-4861-aece-e62b93cd8796',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F6394b6e36fcaff8167e636e3%2Fstock%2FNV%20Highland%20Landscaping%20LLC-2022-12-10T17%3A39%3A51.848Z-12.jpg?alt=media&token=b8b5d20f-6333-44ab-a20f-929abdbd6b48',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F6394b6e36fcaff8167e636e3%2Fstock%2FNV%20Highland%20Landscaping%20LLC-2022-12-12T15%3A26%3A40.523Z-13.jpg?alt=media&token=d4a2e4c6-9adf-4728-a70c-e9253421ee4f',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F6394b6e36fcaff8167e636e3%2Fstock%2FNV%20Highland%20Landscaping%20LLC-2022-12-12T15%3A26%3A40.523Z-14.jpg?alt=media&token=3d3bb823-eb1c-46ae-9e4d-8ae542635884',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F6394b6e36fcaff8167e636e3%2Fstock%2FNV%20Highland%20Landscaping%20LLC-2022-12-12T15%3A26%3A40.523Z-15.jpg?alt=media&token=70ca3bbf-7340-4965-acfb-6dc309641894'

    ]

    return (
        <BaseLayout PageName={`${rpdata?.dbPrincipal?.name}`}>
            {
                rpdata?.dbServices?.map((item, index) => {
                    if (item.name.replaceAll(" ", "-").toLowerCase() === id) {
                        return (
                            <div key={index}>
                                <TransparentHeader
                                    // imagen aleatoria
                                    bgimg={bgheaderImage[index]}
                                    headertitle={item.name}
                                    Subheader={'Our Services'}
                                />
                                <div className="w-[95%] md:w-[90%] mx-auto my-10 block md:flex md:gap-5">
                                    <div className="w-full md:w-9/12 shadow-lg shadow-neutral-800/30 p-5 md:p-10 mb-5">
                                        <img
                                            src={item.description[0].img}
                                            alt='no found'
                                            className="w-full object-cover h-auto md:h-[500px]"
                                        />
                                        <h3>
                                            {
                                                rpdata?.dbSlogan[Math.floor(Math.random() * rpdata?.dbSlogan?.length)].slogan
                                            }
                                        </h3>
                                        {
                                            item.description.map((item, index) => {
                                                return (
                                                    <p key={index}>{item.text} <br /> <br /></p>

                                                )
                                            })
                                        }
                                        <ButtonContent />
                                    </div>
                                    <div className="w-full md:w-1/4 shadow-lg bgBloqueServicesDetail p-5 md:p-5 mb-5 text-white">
                                        <div className="pb-5">
                                            <h4 className="text-center md:text-start"> Our Services</h4>
                                            <ul className="px-1 md:px-4">
                                                {
                                                    rpdata?.dbServices?.slice(0, 7).map((nam, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <Link
                                                                    to={`/${nam.name.replaceAll(" ", "-").toLowerCase()}`}
                                                                    onClick={goToTop}
                                                                    className='flex items-center text-white pb-3'
                                                                >
                                                                    <FaCaretRight />
                                                                    <span className="pl-2">
                                                                        {nam.name}
                                                                    </span>
                                                                </Link>
                                                            </li>

                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="pb-5">
                                            <h4 className="text-center md:text-start">Contact</h4>
                                            <ul className="px-1 md:px-4">
                                                {
                                                    rpdata?.dbPrincipal?.phones.map((phone, index) => {
                                                        return (
                                                            <li key={index} className='flex items-center text-white pb-3'>
                                                                <a
                                                                    href={`tel:+1${phone.phone}`}
                                                                    className='flex items-center'
                                                                >
                                                                    <BiPhoneCall />
                                                                    <span className="pl-2">
                                                                        {phone.phone}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                                {
                                                    rpdata?.dbPrincipal?.emails.map((email, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                className='flex items-center text-white pb-3'
                                                            >
                                                                <a
                                                                    href={`mailto:${email.email}`}
                                                                    className='flex items-center'
                                                                >
                                                                    <MdOutlineEmail />
                                                                    <span className="pl-2">
                                                                        {email.email}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="pb-5">
                                            <h4 className="text-center md:text-start">Opening Hours</h4>
                                            <ul className="px-1 md:px-4">
                                                <li
                                                    key={index}
                                                    className='flex items-center text-white pb-3'
                                                >
                                                    <BiCalendar />
                                                    <span className="pl-2">
                                                        {rpdata?.dbPrincipal?.workdays?.[0]?.day}
                                                    </span>
                                                </li>
                                                <li
                                                    key={index}
                                                    className='flex items-center text-white pb-3'
                                                >
                                                    <FaRegClock />
                                                    <span className="pl-2">
                                                        {rpdata?.dbPrincipal?.workHours?.[0]?.hour}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        {
                                            rpdata?.dbSocialMedia?.redes?.length > 1 ?
                                                <div className="pb-5 text-center md:text-start">
                                                    <h4 >Follow Us</h4>
                                                    <SocialMedia />
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    } else return console.log('Servicio no encontrado')

                })
            }

            <CounterUp  />

            <div className="mt-[-110px]">
                <Values />
            </div>
            <Map />

        </BaseLayout>
    );
}

export default ServicesDetail;